@import "core";
.page-contact {
	@include mediaMin {
		background-color: $color-bg;
	}

	// .layout-sidemenu-content {
	// 	@include mediaMin {
	// 		background-color: $color-white;
	// 		padding: 2rem 30rem 2.8rem 2rem;
	// 		border-radius: $radius-general;
	// 	}
	// }

	.contact-title {
		@include font-bold;
		font-size: 3.2rem;
		margin-bottom: 1.6rem;
	}

	.contact-info {
		border: .1rem solid $color-border;
		border-radius: $radius-small;
		padding: 1.5rem;
	}

		.info-section {
			+ .info-section {
				margin-top: 1.1rem;
			}

			.icon {
				font-size: 1.3rem;
				width: 1.4rem;
				margin-right: .6rem;
			}
		}

		.info-map {
			height: 36.6rem;
			margin-top: 2rem;
		}

		.info-mapbtn {
			margin-top: 2rem;
		}

	// .contact-form {
	// }

		.form-submitbtn {
			width: 12rem;
		}
}