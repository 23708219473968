@import "core";
.mapelement {
	overflow: hidden;
	border-radius: $radius-small;
	height: 50rem;

	.mapelement-marker {
		color: $color-primary;
		position: absolute;
		bottom: 0;
		font-size: 6rem;
		width: 6rem;
		height: 6rem;
		left: -2.1rem;
	}
}